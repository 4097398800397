/* eslint-disable import/prefer-default-export */
import _ from "lodash";

export const checkUrl = _.debounce((src: string, cb: (ok: boolean) => void) => {
  fetch(src)
    .then((response) => {
      cb(response.ok);
    })
    .catch(() => {
      cb(false);
    });
}, 1000);
