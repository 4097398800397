import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useCallback, useContext } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { selectUser } from "@/store/auth";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectDrawerOpen, toggleDrawer } from "@/store/drawer";
import config from "@/config";
import { useTheme } from "@mui/material/styles";
import ColorModeContext from "@/context/ColorModeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { saveUserSettings } from "@/store/settings";
import UserMenu from "./UserMenu";
import DisplayToFullyAuthenticated from "./DisplayToFullyAuthenticated";
import DisplayToAnonymous from "./DisplayToAnonymous";
import DisplayToUnuthenticated from "./DisplayToUnuthenticated";

export interface AppBarProps {
  hideDrawerToggle?: boolean;
}

const AppBar = ({ hideDrawerToggle = false }: AppBarProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const colorMode = useContext(ColorModeContext);

  const user = useAppSelector(selectUser);
  const drawerOpen = useAppSelector(selectDrawerOpen);

  const navigate = useNavigate();

  const handleDrawerOpen = useCallback(() => {
    dispatch(toggleDrawer());
  }, [dispatch]);

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        zIndex: (t) => t.zIndex.drawer + 1,
        ...(drawerOpen && {
          ml: `${config.settings.drawer.width}px`,
          width: `calc(100% - ${config.settings.drawer.width}px)`,
          transition: (t) =>
            t.transitions.create(["margin", "width"], {
              easing: t.transitions.easing.easeOut,
              duration: t.transitions.duration.enteringScreen,
            }),
        }),
      }}
    >
      <Toolbar>
        {!hideDrawerToggle && user.emailVerified && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Self-site
        </Typography>
        <DisplayToAnonymous>
          <Button color="inherit" onClick={() => navigate("/login")}>
            Login
          </Button>
        </DisplayToAnonymous>
        <DisplayToUnuthenticated>
          <Button color="inherit" onClick={() => navigate("/login")}>
            Login
          </Button>
        </DisplayToUnuthenticated>

        <DisplayToFullyAuthenticated>
          {user.emailVerified && (
            <>
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => {
                  const newMode =
                    theme.palette.mode === "light" ? "dark" : "light";

                  colorMode.changeColorMode(newMode);
                  dispatch(saveUserSettings({ mode: newMode }));
                }}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
              {!hideDrawerToggle && user.emailVerified && (
                <Button
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                >
                  [Websites]
                </Button>
              )}
              <Button color="inherit" onClick={() => navigate("/domains")}>
                Domains
              </Button>
            </>
          )}
          <UserMenu />
        </DisplayToFullyAuthenticated>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
