/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import type { IAppUser } from "@/interfaces";
import { auth } from "@/firebase";
import { pickUserFields } from "@/utils";
import isEqual from "react-fast-compare";
import type { RootState } from ".";

export const emptyUser = (): IAppUser => ({
  displayName: null,
  email: null,
  photoURL: null,
  emailVerified: false,
  isAnonymous: true,
  uid: "",
});

export const reloadUser = createAsyncThunk(
  "auth/reloadUser",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (arg: { manual?: boolean }, { getState }) => {
    if (!auth.currentUser) {
      return { user: emptyUser(), token: "" };
    }
    await auth.currentUser.reload();

    const state = getState() as RootState;
    const user = pickUserFields(auth.currentUser);

    return {
      user: pickUserFields(auth.currentUser),
      token: await auth.currentUser.getIdToken(!isEqual(user, state.auth.user)),
    };
  },
);

const initialState = {
  user: emptyUser(),
  token: "",
  reloading: false,
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IAppUser>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(reloadUser.pending, (state, { meta }) => {
        state.reloading = !!meta.arg.manual;
      })
      .addCase(reloadUser.rejected, (state) => {
        state.reloading = false;
      })
      .addCase(reloadUser.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.token = payload.token;
        state.reloading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setToken, setLoading } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserReloading = (state: RootState) => state.auth.reloading;
export const selectUserLoading = (state: RootState) => state.auth.loading;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIsAuthenticated = createSelector(
  selectUser,
  (user) => user.uid.length > 0,
);
export const selectIsAuthenticatedAnonymously = createSelector(
  selectUser,
  (user) => user.isAnonymous,
);

export const selectTokenExpired = createSelector(
  (state) => {
    try {
      return jwtDecode(state.auth.token);
    } catch {
      return { exp: 200000000000 };
    }
  },
  (decodedJwt: any) => decodedJwt.exp * 1000 < Date.now(),
);
export default authSlice.reducer;
