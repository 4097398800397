/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from ".";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    open: false,
  },
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    setDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
    },
  },
});

export const { toggleDrawer, setDrawerOpen } = drawerSlice.actions;

export const selectDrawerOpen = (state: RootState) => state.drawer.open;

export default drawerSlice.reducer;
