/* eslint-disable no-param-reassign */
import api from "@/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ICountry } from "@/interfaces";
import type { RootState } from ".";

export const getCountries = createAsyncThunk("country/getCountries", async () =>
  api.getCountries(),
);

export const countrySlice = createSlice({
  name: "country",
  initialState: {
    countries: [] as ICountry[],
    loading: false,
    error: null,
  },
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
        state.error = null;
      });
  },
});

export const { setCountries } = countrySlice.actions;

export const selectCountries = (state: RootState) => state.country.countries;

export default countrySlice.reducer;
