/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { hasAllBaseProperties } from "@/utils";
import { getGeneratedSite, updateGeneratedSite } from "@/firebase";
import WebsiteData from "@/entities/Website";
import type { IDeploy, IWebsiteData } from "@/interfaces";
import _ from "lodash";
import Deploy from "@/entities/Deploy";
import parseISO from "date-fns/parseISO";
import type { RootState } from ".";

interface WebsiteState {
  current: null | IWebsiteData;
  changed: Partial<IWebsiteData>;
  deploys: IDeploy[];
  saving: boolean;
}

export const getWebsite = createAsyncThunk(
  "website/getWebsite",
  async (id: string) => getGeneratedSite(id),
);

export const saveWebsiteChanges = createAsyncThunk(
  "website/saveWebsiteChanges",
  async (_arg, { getState }) => {
    const {
      website: { current, changed },
    } = getState() as RootState;

    if (!current) {
      throw new Error("No website to update");
    }

    if (_.isEmpty(changed)) {
      throw new Error("No changes");
    }

    await updateGeneratedSite(current.id, changed);

    return { changed };
  },
);

const initialState: WebsiteState = {
  current: null,
  changed: {},
  deploys: [],
  saving: false,
};

export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    setCurrentWebsite: (
      state,
      { payload }: PayloadAction<null | IWebsiteData>,
    ) => {
      state.current = payload;
      state.changed = {};
    },
    setCurrentWebsiteField: (
      state,
      { payload }: PayloadAction<Partial<IWebsiteData>>,
    ) => {
      Object.assign(state.changed, payload);
    },
    resetChanges: (state) => {
      state.changed = {};
    },
    setDomainId: (state, { payload }: PayloadAction<string>) => {
      if (state.current) {
        state.current.domainId = payload;
      }
    },
    setAllSectionsDone: (state, { payload }: PayloadAction<boolean>) => {
      if (state.current) {
        state.current.all_sections_done = payload;
      }
    },
    setDeploys: (state, { payload }: PayloadAction<IDeploy[]>) => {
      state.deploys = payload;
    },
    addDeploy: (state, { payload }: PayloadAction<IDeploy>) => {
      state.deploys = [payload, ...state.deploys];
    },
  },
  extraReducers(builder) {
    builder.addCase(getWebsite.fulfilled, (state, { payload }) => {
      state.current = payload;
      state.changed = {};
    });

    builder
      .addCase(saveWebsiteChanges.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveWebsiteChanges.rejected, (state) => {
        state.saving = false;
      })
      .addCase(saveWebsiteChanges.fulfilled, (state, { payload }) => {
        Object.assign(state.current!, payload.changed);
        state.changed = {};
        state.saving = false;
      });
  },
});

export const {
  setCurrentWebsite,
  setDomainId,
  setAllSectionsDone,
  setDeploys,
  addDeploy,
  setCurrentWebsiteField,
  resetChanges,
} = websiteSlice.actions;

export const selectCurrentWebsite = createSelector(
  (state: RootState) => state.website.current,
  (current) =>
    new WebsiteData({
      ...current,
      lastDeployDate: current?.lastDeployDate
        ? parseISO(current.lastDeployDate)
        : null,
    }),
);

export const selectAllBaseProperties = createSelector(
  (state: RootState) => state.website.current,
  (data) => (data !== null ? hasAllBaseProperties(data) : false),
);
export const selectAllSectionsDone = createSelector(
  (state: RootState) => state.website.current,
  (current) => current?.all_sections_done ?? false,
);
export const selectTemplate = createSelector(
  (state: RootState) => state.website.current,
  (current) => current?.template ?? null,
);
export const selectShowIframe = createSelector(
  (state: RootState) => state.website.current,
  (current) => Boolean(current?.template && current.topic),
);
export const selectRawRecentDeploy = createSelector(
  (state: RootState) => state.website.deploys,
  (deploys) => _.first(deploys) ?? null,
);
export const selectRecentDeploy = createSelector(
  selectRawRecentDeploy,
  (deploy) =>
    deploy
      ? new Deploy({
          ...deploy,
          createdAt: parseISO(deploy.createdAt),
          lastStatusChange: parseISO(deploy.lastStatusChange),
        })
      : null,
);
export const selectSaving = (state: RootState) => state.website.saving;

export default websiteSlice.reducer;
