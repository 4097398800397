/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ref, list } from "firebase/storage";
import { storage } from "@/firebase";
import { getStorageURL } from "@/utils";
import type { RootState } from ".";

export interface Image {
  name: string;
  url: string;
  originalName?: string;
}

const maxResults = 10;

const initialState = {
  images: [] as Image[],
  imagesNextPage: null as string | null,
  imagesLoading: false,
  generatedImages: [] as Image[],
  generatedImagesNextPage: null as string | null,
  generatedImagesLoading: false,
};

const getImagesFromDir = async (dir: string, pageToken?: string) => {
  const listRef = ref(storage, dir);
  const { items: refs, nextPageToken = null } = await list(listRef, {
    maxResults,
    pageToken,
  });

  return {
    nextPageToken,
    items: refs.map(
      (itemRef): Image => ({
        name: itemRef.name,
        url: getStorageURL(itemRef.bucket, itemRef.fullPath),
      }),
    ),
  };
};

export const getImages = createAsyncThunk(
  "image/getImages",
  async ({ userId }: { userId: string }) =>
    getImagesFromDir(`images/${userId}`),
  {
    condition: (_, { getState }) => {
      const { image } = getState() as RootState;
      return !image.imagesLoading;
    },
  },
);

export const getMoreImages = createAsyncThunk(
  "image/getMoreImages",
  async ({ userId, pageToken }: { userId: string; pageToken: string }) =>
    getImagesFromDir(`images/${userId}`, pageToken),
  {
    condition: (_, { getState }) => {
      const { image } = getState() as RootState;
      return !image.imagesLoading;
    },
  },
);

export const getGeneratedImages = createAsyncThunk(
  "image/getGeneratedImages",
  async ({ userId }: { userId: string }) =>
    getImagesFromDir(`generated-images/${userId}`),
  {
    condition: (_, { getState }) => {
      const { image } = getState() as RootState;
      return !image.generatedImagesLoading;
    },
  },
);

export const getMoreGeneratedImages = createAsyncThunk(
  "image/getMoreGeneratedImages",
  async ({ userId, pageToken }: { userId: string; pageToken: string }) =>
    getImagesFromDir(`generated-images/${userId}`, pageToken),
  {
    condition: (_, { getState }) => {
      const { image } = getState() as RootState;
      return !image.generatedImagesLoading;
    },
  },
);

export const imageSlice = createSlice({
  name: "image",
  initialState,
  reducers: {
    setList: (state, { payload }) => {
      state.images = payload;
    },
    addItem: (state, { payload }) => {
      state.images.push(payload);
    },
    resetLoadings: (state) => {
      state.imagesLoading = false;
      state.generatedImagesLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImages.rejected, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getImages.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getImages.fulfilled, (state, { payload }) => {
        state.images = payload.items;
        state.imagesNextPage = payload.nextPageToken ?? null;
        state.imagesLoading = false;
      });

    builder
      .addCase(getMoreImages.rejected, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getMoreImages.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getMoreImages.fulfilled, (state, { payload }) => {
        state.images.push(...payload.items);
        state.imagesNextPage = payload.nextPageToken ?? null;
        state.imagesLoading = false;
      });

    builder
      .addCase(getGeneratedImages.rejected, (state) => {
        state.generatedImagesLoading = true;
      })
      .addCase(getGeneratedImages.pending, (state) => {
        state.generatedImagesLoading = true;
      })
      .addCase(getGeneratedImages.fulfilled, (state, { payload }) => {
        state.generatedImages = payload.items;
        state.generatedImagesNextPage = payload.nextPageToken ?? null;
        state.generatedImagesLoading = false;
      });

    builder
      .addCase(getMoreGeneratedImages.pending, (state) => {
        state.generatedImagesLoading = true;
      })
      .addCase(getMoreGeneratedImages.rejected, (state) => {
        state.generatedImagesLoading = true;
      })
      .addCase(getMoreGeneratedImages.fulfilled, (state, { payload }) => {
        state.generatedImages.push(...payload.items);
        state.generatedImagesNextPage = payload.nextPageToken ?? null;
        state.generatedImagesLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setList, addItem, resetLoadings } = imageSlice.actions;

export const selectImages = (state: RootState) => state.image.images;
export const selectImagesLoading = (state: RootState) =>
  state.image.imagesLoading;
export const selectImagesNextPageToken = (state: RootState) =>
  state.image.imagesNextPage;

export const selectGeneratedImages = (state: RootState) =>
  state.image.generatedImages;
export const selectGeneratedImagesLoading = (state: RootState) =>
  state.image.generatedImagesLoading;
export const selectGeneratedImagesNextPageToken = (state: RootState) =>
  state.image.generatedImagesNextPage;

export default imageSlice.reducer;
