/* eslint-disable import/prefer-default-export */
import type { Analytics } from "firebase/analytics";
import { getAnalytics } from "firebase/analytics";

import app from "./app";

let analyticsCopy: Analytics | null = null;
try {
  analyticsCopy = getAnalytics(app);
} catch (err) {
  // eslint-disable-next-line no-console
  console.error(err);
}
export const analytics = analyticsCopy;
