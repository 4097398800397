import {
  useMemo,
  useState,
  type PropsWithChildren,
  memo,
  useEffect,
} from "react";
import type { PaletteMode, ThemeOptions } from "@mui/material";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import _ from "lodash";
import { store, persistor } from "./store/index";
import config from "./config";
import ColorModeContext from "./context/ColorModeContext";
import { setUpInterceptor } from "./api";

const primary = "#022D53";

const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
  },
  components: {
    MuiAppBar: { styleOverrides: { root: { backgroundColor: primary } } },
    MuiLink: {
      styleOverrides: {
        root: { color: "white" },
      },
    },
  },
};

const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: primary,
    },
    secondary: {
      main: "#830909",
    },
  },
  components: {
    MuiAppBar: { styleOverrides: { root: { backgroundColor: primary } } },
    MuiLink: {
      styleOverrides: {
        root: { color: "primary" },
      },
    },
  },
};

const getDesignTokens = (mode: PaletteMode): ThemeOptions =>
  mode === "dark" ? darkTheme : lightTheme;

const AppProviders = memo(({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode = useMemo(
    () => ({
      changeColorMode: _.debounce((newMode: PaletteMode) => {
        // eslint-disable-next-line no-console
        console.log("new color mode", newMode);
        setMode(newMode);
      }, 100),
    }),
    [],
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    setUpInterceptor(store);
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PayPalScriptProvider options={config.paypal}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              {children}
            </PersistGate>
          </Provider>
        </PayPalScriptProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
});

export default AppProviders;
