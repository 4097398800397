import axios from "axios";
import axiosRetry from "axios-retry";
import config from "./config";
import type { ITemplate, ICountry } from "./interfaces";
import type { AppStore } from "./store";

const instance = axios.create({ baseURL: config.apiBaseUrl });
const authInstance = axios.create({ baseURL: config.apiBaseUrl });

const retryDelay = (retryCount: number) => retryCount * 1000;

axiosRetry(instance, { retries: 3, retryDelay });
axiosRetry(authInstance, { retries: 3, retryDelay });

export const setUpInterceptor = (store: AppStore) => {
  authInstance.interceptors.request.use(async (requestConfig) => {
    const authToken = store.getState().auth.token;
    if (authToken.length > 0) {
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    return requestConfig;
  });
};

const makeGetRequest = async <T = unknown>(
  path: string,
  signal?: AbortSignal,
) => {
  const { data } = await instance.get<T>(path, {
    signal,
  });

  return data;
};

const makeAuthGetRequest = async <T = unknown>(
  path: string,
  signal?: AbortSignal,
) => {
  const { data } = await authInstance.get<T>(path, {
    signal,
  });

  return data;
};

const makeAuthPostRequest = async <T = unknown, B = unknown>(
  path: string,
  body: B,
  timeout = 540,
) => {
  const { data } = await authInstance.post<T>(path, body, {
    timeout: timeout * 1000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return data;
};

const makeAuthPutRequest = async <T = unknown, B = unknown>(
  path: string,
  body: B,
  timeout = 540,
) => {
  const { data } = await authInstance.put<T>(path, body, {
    timeout: timeout * 1000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return data;
};

const checkExternalDomainRequiredRecords = async (domain: string) =>
  makeAuthPutRequest<{ allOk: boolean }>(
    `domains/external/${domain}/check-required-records`,
    {},
  );

const verifyExternalDomain = async (domain: string) =>
  makeAuthPutRequest(`domains/external/${domain}/verify`, {});

const verifyExternalDomainCert = async (domain: string) =>
  makeAuthPutRequest(`domains/external/${domain}/verify-cert`, {});

const verifyExternalDomainDistribution = async (domain: string) =>
  makeAuthPutRequest(`domains/external/${domain}/verify-distribution`, {});

const addExternalDomain = async (domain: string, websiteId: string) =>
  makeAuthPostRequest<{ txtRecord: string }>("domains/external", {
    domain,
    websiteId,
  });

const importDomain = async (domain: string) =>
  makeAuthPutRequest<{ nameServers: string[] }>(`domains/import`, { domain });

const captureDomainPurchase = async (orderID: string) =>
  makeAuthPostRequest<{ id: string; details: any[]; debug_id: string }, {}>(
    `domains/purchases/${orderID}/capture`,
    {},
  );

const getCountries = async () =>
  makeGetRequest<ICountry[]>(
    `${window.location.protocol}//${window.location.host}/countries.json`,
  );

const assignWebsiteToDomain = async (domain: string, websiteId: string) =>
  makeAuthPutRequest<string, { websiteId: string }>(
    `domains/${encodeURIComponent(domain)}`,
    {
      websiteId,
    },
  );

const unlinkDomain = async (domain: string) =>
  makeAuthPutRequest<string, {}>(
    `domains/${encodeURIComponent(domain)}/unlink`,
    {},
  );

const createDomainPurchase = async (domain: string) =>
  makeAuthPostRequest<{ paypalOrderId: string; id: string }>(
    "domains/purchases",
    {
      domain,
      test: import.meta.env.DEV,
    },
  );

const checkDomainAvailability = async (domain: string, signal?: AbortSignal) =>
  makeAuthGetRequest<{ available: boolean; suggestions: string[] }>(
    `domains/availability/${encodeURIComponent(domain)}`,
    signal,
  );

const checkDomainPrice = async (tld: string, signal?: AbortSignal) =>
  makeAuthGetRequest<{ currency: string; price: number }>(
    `domains/pricing/${tld}`,
    signal,
  );

const getTemplates = async (signal?: AbortSignal) =>
  makeGetRequest<ITemplate[]>(
    `${window.location.protocol}//${window.location.host}/templates.json`,
    signal,
  );

const ask = async (
  question: string,
  conversationId: string,
  type: string,
  websiteId: string,
) =>
  makeAuthPutRequest("ask", {
    conversationId,
    question: question.trim(),
    type,
    websiteId,
  });

const generateImage = async (field: string, websiteId: string) =>
  makeAuthPostRequest<{ url: string }>("images/generate", {
    field,
    websiteId,
  });

const deploySite = async (websiteId: string) =>
  makeAuthPutRequest<string, {}>(`generated-sites/${websiteId}/deploy`, {});

const searchFontawesomeIcons = async (query: string) =>
  makeGetRequest<{ id: string; label: string }[]>(
    `fontawesome/icons/${encodeURIComponent(query)}`,
  );

export default {
  deploySite,
  assignWebsiteToDomain,
  ask,
  getTemplates,
  generateImage,
  checkDomainAvailability,
  checkDomainPrice,
  createDomainPurchase,
  getCountries,
  captureDomainPurchase,
  importDomain,
  addExternalDomain,
  verifyExternalDomain,
  verifyExternalDomainCert,
  checkExternalDomainRequiredRecords,
  verifyExternalDomainDistribution,
  searchFontawesomeIcons,
  unlinkDomain,
};
