import { useAppSelector } from "@/store";
import { selectErrorMessage, selectSuccessMessage } from "@/store/flashMessage";
import { useEffect } from "react";
import toast from "react-hot-toast";

export default () => {
  const successMessage = useAppSelector(selectSuccessMessage);
  const errorMessage = useAppSelector(selectErrorMessage);

  useEffect(() => {
    if (successMessage !== null) {
      toast.success(successMessage, { id: "success" });
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage !== null) {
      toast.error(errorMessage, { id: "error" });
    }
  }, [errorMessage]);
};
