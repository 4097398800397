import { useAppSelector } from "@/store";
import { selectIsAuthenticated, selectUserLoading } from "@/store/auth";
import LinearProgress from "@mui/material/LinearProgress";
import type { PropsWithChildren } from "react";
import { memo } from "react";
import { Navigate } from "react-router-dom";

const AllowAnyAuthRoute = memo(({ children }: PropsWithChildren) => {
  const isLoading = useAppSelector(selectUserLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (isLoading) {
    return <LinearProgress variant="indeterminate" />;
  }

  return isAuthenticated ? children : <Navigate to="/unauthenticated" />;
});

export default AllowAnyAuthRoute;
