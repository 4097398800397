import { useAppDispatch } from "@/store";
import { onIdTokenChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth } from "@/firebase";
import { setToken } from "@/store/auth";

export const useIdTokenChanged = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setToken(""));

    const unsubscribeIdToken = onIdTokenChanged(auth, (u) => {
      if (!u) {
        dispatch(setToken(""));
        return;
      }
      u.getIdToken().then((idToken) => {
        dispatch(setToken(idToken));
      });
    });

    return () => {
      unsubscribeIdToken();
    };
  }, [dispatch]);
};

export default useIdTokenChanged;
