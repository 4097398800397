/* eslint-disable import/prefer-default-export */
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue as getValueFunc,
} from "firebase/remote-config";
import app from "./app";

export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60 * 1000;

let loaded = false;
fetchAndActivate(remoteConfig)
  .then(() => {
    // eslint-disable-next-line no-console
    console.log("Remote configuration loaded");
    loaded = true;
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });

export const getValue = (key: string) =>
  loaded ? getValueFunc(remoteConfig, key).asString() : null;
