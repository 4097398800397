import * as Sentry from "@sentry/react";
import ErrorFallbackComponent from "@/components/ErrorFallbackComponent";
import type { PropsWithChildren } from "react";

const ErrorBoundaryLayout = Sentry.withErrorBoundary(
  ({ children }: PropsWithChildren) => children,
  {
    // eslint-disable-next-line react/jsx-props-no-spreading
    fallback: (props) => <ErrorFallbackComponent {...props} />,
  },
);

export default ErrorBoundaryLayout;
