import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import capitalize from "lodash/capitalize";
import get from "lodash/get";
import { flashMessageSlice } from "../flashMessage";

/* eslint-disable import/prefer-default-export */
export const showMessage =
  (
    message: string,
    type: "success" | "error",
  ): ThunkAction<void, unknown, unknown, AnyAction> =>
  (dispatch) => {
    const action = get(
      flashMessageSlice.actions,
      `set${capitalize(type)}Message`,
    ) as any;

    if (action !== undefined) {
      dispatch(action(message));

      setTimeout(() => {
        dispatch(action(null));
      }, 6000);
    }
  };
