import { configureStore } from "@reduxjs/toolkit";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "@/storage";
import { getPersistConfig } from "redux-deep-persist";
import * as Sentry from "@sentry/react";
import rootReducer from "./rootReducer";
import errorMiddleware from "./middleware/error";

const persistConfig = getPersistConfig({
  key: "root",
  version: 3,
  storage,
  whitelist: [
    "auth",
    "domain",
    "contact",
    "image",
    "template",
    "settings",
    "country",
    "conversation.generatedId",
    "conversation.websiteId",
    "conversation.typing",
  ],
  rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  devTools: import.meta.env.DEV,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(errorMiddleware),
  enhancers: [sentryReduxEnhancer],
});

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

export default { store, persistor };
