import type { User } from "firebase/auth";
import {
  getAuth,
  signOut as signOutFunc,
  GoogleAuthProvider,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFunc,
  sendEmailVerification as sendEmailVerificationFunc,
  signInAnonymously as signInAnonymouslyFunc,
  linkWithCredential as linkWithCredentialFunc,
  EmailAuthProvider,
  linkWithPopup,
  signInWithPopup,
} from "firebase/auth";

import reduxStorage from "@/storage";

import cookies, { COOKIE_NAME } from "@/cookies";
import app from "./app";

export const auth = getAuth(app);

export const signOut = async () => {
  await signOutFunc(auth);
  await reduxStorage.clear();
  cookies.remove(COOKIE_NAME);
};

const googleAuthprovider = new GoogleAuthProvider();

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleAuthprovider);
export const linkWithGooglePopup = () => {
  if (!auth.currentUser) {
    return null;
  }

  return linkWithPopup(auth.currentUser, googleAuthprovider);
};

export const signInAnonymously = () => signInAnonymouslyFunc(auth);

export const signInWithEmailAndPassword = (email: string, password: string) =>
  signInWithEmailAndPasswordFunc(auth, email, password);

export const sendEmailVerification = (user?: User) =>
  sendEmailVerificationFunc(user ?? auth.currentUser!);

export const reloadUser = () => auth.currentUser && auth.currentUser.reload();

export const linkWithCredential = (email: string, password: string) => {
  if (!auth.currentUser) {
    return null;
  }
  const credential = EmailAuthProvider.credential(email, password);

  return linkWithCredentialFunc(auth.currentUser, credential);
};
