import { useAppSelector } from "@/store";
import {
  selectIsAuthenticated,
  selectIsAuthenticatedAnonymously,
} from "@/store/auth";
import { memo, type PropsWithChildren } from "react";

const DisplayToAnonymous = memo(({ children }: PropsWithChildren) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAuthenticatedAnonymously = useAppSelector(
    selectIsAuthenticatedAnonymously,
  );

  return isAuthenticated && isAuthenticatedAnonymously && children;
});

export default DisplayToAnonymous;
