import type { IWebsiteData } from "@/interfaces";

export const baseProperties = [
  "header_background_url",
  "logo_picture_url",
  "title",
  "company_name",
  "welcome_text",
  "main_text",
];

export const hasAllBaseProperties = (obj: Partial<IWebsiteData>) =>
  baseProperties.every((item) =>
    Object.prototype.hasOwnProperty.call(obj, item),
  );
