/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from ".";

interface FlashMessageState {
  successMessage: string | null;
  errorMessage: string | null;
}

const initialState: FlashMessageState = {
  successMessage: null,
  errorMessage: null,
};

export const flashMessageSlice = createSlice({
  name: "flashMessage",
  initialState,
  reducers: {
    setSuccessMessage: (
      state,
      { payload }: PayloadAction<FlashMessageState["successMessage"]>,
    ) => {
      state.successMessage = payload;
    },
    setErrorMessage: (
      state,
      { payload }: PayloadAction<FlashMessageState["errorMessage"]>,
    ) => {
      state.errorMessage = payload;
    },
  },
});

export const { setSuccessMessage, setErrorMessage } = flashMessageSlice.actions;

export const selectSuccessMessage = (state: RootState) =>
  state.flashMessage.successMessage;
export const selectErrorMessage = (state: RootState) =>
  state.flashMessage.errorMessage;

export default flashMessageSlice.reducer;
