import type {
  ILocation,
  IPortfolioItem,
  IServiceItem,
  ITeamMember,
  ITestimonial,
  ITimelineItem,
  IWebsiteData,
} from "@/interfaces";

export default class WebsiteData
  implements Omit<IWebsiteData, "lastDeployDate">
{
  id: string;

  domainId: string | null = null;

  topic: string;

  ceo_text: string;

  ceo_photo_url: string;

  header_background_url: string;

  logo_picture_url: string;

  title: string;

  welcome_text: string;

  main_text: string;

  company_name: string;

  company_address: string;

  company_location: ILocation;

  services_description: string;

  portfolio_description: string;

  about_description: string;

  about_video: string;

  team_members_description: string;

  team_summary: string;

  contact_description: string;

  contact_phone: string;

  sections: string[];

  services_items: IServiceItem[] = [];

  portfolio_items: IPortfolioItem[] = [];

  about_timeline_items: ITimelineItem[] = [];

  team_members: ITeamMember[] = [];

  testimonials: ITestimonial[] = [];

  funfacts_satisfied_clients: number;

  funfacts_hours_of_work: number;

  funfacts_awards: number;

  userId: string;

  all_sections_done: boolean = false;

  template: string;

  updatedAt: Date;

  tags: string[] = [];

  saved_to_bucket: boolean = false;

  lastDeployDate: Date | null = null;

  constructor(partial: Partial<WebsiteData>) {
    Object.assign(this, partial);
  }
}
