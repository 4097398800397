import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { memo, useCallback, useEffect } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import {
  getConversations,
  markConversationFavourite,
  selectConversationHistory,
} from "@/store/conversation";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser } from "@/store/auth";
import { useAppDispatch, useAppSelector } from "@/store";
import Divider from "@mui/material/Divider";
import config from "@/config";
import { selectDrawerOpen, toggleDrawer } from "@/store/drawer";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Rating from "@mui/material/Rating";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Drawer = memo(() => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(selectDrawerOpen);
  const history = useAppSelector(selectConversationHistory);
  const user = useAppSelector(selectUser);

  const navigate = useNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();

  useEffect(() => {
    if (user.uid.length > 0 && user.emailVerified) {
      const promise = dispatch(getConversations({ userId: user.uid }));

      return () => {
        promise.abort();
      };
    }

    return () => {};
  }, [dispatch, user.emailVerified, user.uid]);

  const handleDrawerClose = useCallback(() => {
    dispatch(toggleDrawer());
  }, [dispatch]);

  return (
    <MuiDrawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        flexShrink: 0,
        width: config.settings.drawer.width,
        "& .MuiDrawer-paper": {
          width: config.settings.drawer.width,
          boxSizing: "border-box",
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box role="presentation">
        <List>
          <ListItem disablePadding>
            <ListItemButton
              selected={!conversationId}
              onClick={() => {
                navigate(`/`);
              }}
            >
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText
                primary="Current conversation"
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          {history.map((item) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                selected={item.id === conversationId}
                onClick={() => {
                  navigate(`/chat/${item.id}`);
                }}
              >
                <ListItemIcon>
                  <Rating
                    name="size-large"
                    defaultValue={Number(item.favourite)}
                    size="large"
                    max={1}
                    onChange={(event, newValue) => {
                      dispatch(
                        markConversationFavourite({
                          id: item.docId,
                          favourite: Boolean(newValue),
                        }),
                      );
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </MuiDrawer>
  );
});

export default Drawer;
