import {
  getMessaging,
  onMessage,
  isSupported,
  getToken,
} from "firebase/messaging";
import app, { vapidKey } from "./app";

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    // eslint-disable-next-line no-console
    console.log("FCM not supported this browser");
    return null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
})();

export const onMessageListener = async (
  cb: Parameters<typeof onMessage>[1],
) => {
  const messagingResolve = await messaging;
  if (messagingResolve) {
    return onMessage(messagingResolve, cb);
  }

  return null;
};

export const getMessagingToken = async () => {
  const messagingResolve = await messaging;
  if (messagingResolve) {
    return getToken(messagingResolve, { vapidKey });
  }

  return null;
};
