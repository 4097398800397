export enum ConversationType {
  Base = "base",
  Section = "section",
}

export interface IListConversation {
  id: string;
  name: string;
  type: ConversationType;
  docId: string;
  favourite: boolean;
}

export interface IQuestionAndAnswer {
  id: string;
  content: string | null;
  role: "function" | "user" | "system" | "assistant";
  function_call?: any;
  name?: string;
}

export interface IConversation {
  id: string;
  name: string;
  conversation: IQuestionAndAnswer[];
  userId: string;
  docId: string;
  websiteId: string;
  type: ConversationType;
  favourite: boolean;
}
