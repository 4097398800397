import type { ExtraData, IPurchase } from "@/interfaces";
import { formatPrice } from "@/utils";

export default class Purchase implements IPurchase {
  id: string;

  userId: string;

  status: "pending" | "completed" | "canceled";

  approved: boolean;

  provider: string;

  providerPaymentId: string;

  amount: number;

  currency: string;

  extraData: ExtraData;

  isTest: boolean;

  constructor(partial: Partial<Purchase>) {
    Object.assign(this, partial);
  }

  get completed() {
    return this.status === "completed";
  }

  get displayPrice() {
    return formatPrice(this.amount, this.currency);
  }
}
