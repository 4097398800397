import { useAppSelector } from "@/store";
import { selectIsAuthenticated } from "@/store/auth";
import { memo, type PropsWithChildren } from "react";

const DisplayToUnuthenticated = memo(({ children }: PropsWithChildren) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return !isAuthenticated && children;
});

export default DisplayToUnuthenticated;
