import { useAppSelector } from "@/store";
import {
  selectIsAuthenticated,
  selectIsAuthenticatedAnonymously,
  selectUserLoading,
} from "@/store/auth";
import LinearProgress from "@mui/material/LinearProgress";
import type { PropsWithChildren } from "react";
import { memo } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = memo(({ children }: PropsWithChildren) => {
  const isLoading = useAppSelector(selectUserLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAuthenticatedAnonymously = useAppSelector(
    selectIsAuthenticatedAnonymously,
  );

  if (isLoading) {
    return <LinearProgress variant="indeterminate" />;
  }

  return isAuthenticated && !isAuthenticatedAnonymously ? (
    children
  ) : (
    <Navigate to="/login" />
  );
});

export default ProtectedRoute;
