import type { DeployStatus, IDeploy } from "@/interfaces";

export default class Deploy
  implements Omit<IDeploy, "createdAt" | "lastStatusChange">
{
  id: string;

  userId: string;

  websiteId: string;

  status: DeployStatus;

  createdAt: Date;

  lastStatusChange: Date;

  constructor(partial: Partial<Deploy>) {
    Object.assign(this, partial);
  }
}
