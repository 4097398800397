/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@/api";
import type { ITemplate } from "@/interfaces";
import type { RootState } from ".";

export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  (_, { signal }) => api.getTemplates(signal),
);

const initialState = {
  list: [] as ITemplate[],
  loading: false,
};

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(getTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTemplates.rejected, (state) => {
        state.loading = false;
        state.list = [];
      });
  },
});

export const selectTemplateList = (state: RootState) => state.template.list;
export const selectTemplateLoading = (state: RootState) =>
  state.template.loading;

export default templateSlice.reducer;
