import type { IDomainAwsData, IDomain, DomainType } from "@/interfaces";

export default class Domain implements IDomain {
  aws: IDomainAwsData;

  type: DomainType;

  txtRecord?: string;

  txtRecordVerified?: boolean;

  nameServers?: string[];

  paid: boolean = false;

  purchaseId: string;

  reserved: boolean = false;

  websiteId: string | null = null;

  name: string;

  userId: string;

  id: string;

  constructor(partial: Partial<IDomain>) {
    Object.assign(this, partial);
  }

  get registered() {
    return this.aws.hostedZoneId !== null;
  }
}
