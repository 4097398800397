/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getUserDomains,
  getOnePurchase as getOnePurchaseFunc,
} from "@/firebase";
import _ from "lodash";
import api from "@/api";
import Domain from "@/entities/Domain";
import type { IDomain } from "@/interfaces";
import type { RootState } from ".";
import { setDomainId } from "./website";

export const getDomains = createAsyncThunk(
  "domain/getDomains",
  async ({ userId }: { userId: string }) => getUserDomains(userId),
  {
    condition(_arg, { getState }) {
      const state = getState() as RootState;
      return !state.auth.user.isAnonymous;
    },
  },
);

export const getDomainByPurchaseId = createAsyncThunk(
  "domain/getDomainByPurchaseId",
  async ({ purchaseId }: { purchaseId: string }) =>
    getOnePurchaseFunc(purchaseId),
);

export const assignWebsiteIdToDomain = createAsyncThunk(
  "domain/assignWebsiteIdToDomain",
  async (
    { domain, websiteId }: { domain: IDomain; websiteId: string },
    { dispatch },
  ) => {
    await api.assignWebsiteToDomain(domain.name, websiteId);

    dispatch(setDomainId(domain.id));
  },
);

export const unlinkDomain = createAsyncThunk(
  "domain/unlinkDomain",
  async ({ domainId }: { domainId: string }) => api.unlinkDomain(domainId),
);

export const addExternalDomain = createAsyncThunk(
  "domain/addExternalDomain",
  async ({ domain, websiteId }: { domain: string; websiteId: string }) =>
    api.addExternalDomain(domain, websiteId),
);

type ActionName =
  | "getDomains"
  | "assignWebsiteIdToDomain"
  | "unlinkDomain"
  | "getDomainByPurchaseId"
  | "addExternalDomain";

export const domainSlice = createSlice({
  name: "domain",
  initialState: {
    list: [] as IDomain[],
    loading: {
      addExternalDomain: false,
      assignWebsiteIdToDomain: false,
      getDomainByPurchaseId: false,
      getDomains: false,
      unlinkDomain: false,
    } as Record<ActionName, boolean>,
    current: null as IDomain | null,
    selected: null as IDomain | null,
    purchasedDomain: null as string | null,
    purchaseId: null as string | null,
  },
  reducers: {
    setDomainList: (state, { payload }: PayloadAction<IDomain[]>) => {
      state.list = payload;
    },
    setSelectedDomain: (state, { payload }: PayloadAction<IDomain | null>) => {
      state.selected = payload;
    },
    setPurchasedDomain: (state, { payload }: PayloadAction<string | null>) => {
      state.purchasedDomain = payload;
    },
    setPurchaseId: (state, { payload }: PayloadAction<string | null>) => {
      state.purchaseId = payload;
    },
    setCurrentDomain: (state, { payload }: PayloadAction<IDomain | null>) => {
      state.current = payload;
    },
    setLoading: (
      state,
      { payload }: PayloadAction<{ action: ActionName; value: boolean }>,
    ) => {
      state.loading[payload.action] = payload.value;
    },
    resetLoading: (state) => {
      state.loading = {
        addExternalDomain: false,
        assignWebsiteIdToDomain: false,
        getDomainByPurchaseId: false,
        getDomains: false,
        unlinkDomain: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDomains.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading.getDomains = false;
      })
      .addCase(getDomains.rejected, (state) => {
        state.list = [];
        state.loading.getDomains = false;
      })
      .addCase(getDomains.pending, (state) => {
        state.loading.getDomains = true;
      });

    builder
      .addCase(assignWebsiteIdToDomain.pending, (state) => {
        state.loading.assignWebsiteIdToDomain = true;
      })
      .addCase(assignWebsiteIdToDomain.rejected, (state) => {
        state.loading.assignWebsiteIdToDomain = false;
      })
      .addCase(assignWebsiteIdToDomain.fulfilled, (state, { meta }) => {
        const domain = _.find(state.list, (d) => d.id === meta.arg.domain.id);
        if (domain) {
          domain.websiteId = meta.arg.websiteId;
          domain.reserved = true;
        }

        state.loading.assignWebsiteIdToDomain = false;
      });

    builder
      .addCase(unlinkDomain.pending, (state) => {
        state.loading.unlinkDomain = true;
      })
      .addCase(unlinkDomain.fulfilled, (state) => {
        state.loading.unlinkDomain = false;
      })
      .addCase(unlinkDomain.rejected, (state) => {
        state.loading.unlinkDomain = false;
      });

    builder
      .addCase(addExternalDomain.pending, (state) => {
        state.loading.addExternalDomain = true;
      })
      .addCase(addExternalDomain.fulfilled, (state) => {
        state.loading.addExternalDomain = false;
      })
      .addCase(addExternalDomain.rejected, (state) => {
        state.loading.addExternalDomain = false;
      });

    builder
      .addCase(getDomainByPurchaseId.fulfilled, (state, { payload }) => {
        state.purchasedDomain = (payload?.extraData?.domain as string) ?? null;
        state.purchaseId = payload?.id ?? null;
        state.loading.getDomainByPurchaseId = false;
      })
      .addCase(getDomainByPurchaseId.rejected, (state) => {
        state.purchasedDomain = null;
        state.purchaseId = null;
        state.loading.getDomainByPurchaseId = false;
      })
      .addCase(getDomainByPurchaseId.pending, (state) => {
        state.loading.getDomainByPurchaseId = true;
        state.purchasedDomain = null;
        state.purchaseId = null;
      });
  },
});

export const {
  setDomainList,
  setSelectedDomain,
  setPurchasedDomain,
  setPurchaseId,
  setCurrentDomain,
  setLoading,
  resetLoading,
} = domainSlice.actions;

export const selectDomainList = createSelector(
  (state: RootState) => state.domain.list,
  (list) => list.map((item) => new Domain(item)),
);
export const selectPurchasedDomain = (state: RootState) =>
  state.domain.purchasedDomain;
export const selectPurchaseId = (state: RootState) => state.domain.purchaseId;
export const selectCurrentDomain = (state: RootState) => state.domain.current;
export const selectDomainsLoading =
  (action: ActionName) => (state: RootState) =>
    !!state.domain.loading[action];

export default domainSlice.reducer;
