import { createBrowserRouter } from "react-router-dom";
import MainLayout from "@/layouts/MainLayout";
import * as Sentry from "@sentry/react";
import ProtectedRoute from "./routes/_protected";
import OnlyUnauthenticatedRoute from "./routes/_only-unauthenticated";
import AllowAnyAuthRoute from "./routes/_allow-any-auth";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    id: "without_drawer_toggle",
    Component: () => <MainLayout />,
    children: [
      {
        id: "domains",
        path: "/domains",
        lazy: async () => {
          const { Component } = await import("@/routes/domains");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "import-domain",
        path: "/import-domain",
        lazy: async () => {
          const { Component } = await import("@/routes/import-domain");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "new-domain",
        path: "/new-domain",
        lazy: async () => {
          const { Component } = await import("@/routes/new-domain");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "editor",
        path: "/editor/:websiteId",
        lazy: async () => {
          const { Component } = await import("@/routes/editor");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "purchase-domain",
        path: "/purchase-domain",
        lazy: async () => {
          const { Component } = await import("@/routes/purchase-domain");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "payment-status",
        path: "/payment-status/:purchaseId",
        lazy: async () => {
          const { Component } = await import("@/routes/payment-status");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
      {
        id: "retry-payment",
        path: "/retry-payment/:purchaseId",
        lazy: async () => {
          const { Component } = await import("@/routes/retry-payment");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
    ],
  },
  {
    id: "with_drawer_toggle",
    Component: () => <MainLayout hideDrawer={false} />,
    children: [
      {
        id: "root",
        path: "/",
        lazy: async () => {
          const { Component } = await import("@/routes/root");
          return {
            element: (
              <AllowAnyAuthRoute>
                <Component />
              </AllowAnyAuthRoute>
            ),
          };
        },
      },
      {
        id: "chat",
        path: "/chat/:conversationId",
        lazy: async () => {
          const { Component } = await import("@/routes/chat");
          return {
            element: (
              <ProtectedRoute>
                <Component />
              </ProtectedRoute>
            ),
          };
        },
      },
    ],
  },
  {
    Component: () => <MainLayout mode="dark" />,
    children: [
      {
        path: "/unauthenticated",
        lazy: async () => {
          const { Component } = await import("@/routes/unauthenticated");
          return {
            element: (
              <OnlyUnauthenticatedRoute>
                <Component />
              </OnlyUnauthenticatedRoute>
            ),
          };
        },
      },
      {
        path: "/login",
        lazy: async () => {
          const { Component } = await import("@/routes/login");
          return {
            element: (
              <AllowAnyAuthRoute>
                <Component />
              </AllowAnyAuthRoute>
            ),
          };
        },
      },
      {
        path: "/signup",
        lazy: async () => {
          const { Component } = await import("@/routes/signup");
          return {
            element: (
              <AllowAnyAuthRoute>
                <Component />
              </AllowAnyAuthRoute>
            ),
          };
        },
      },
    ],
  },
]);

export default router;
