/* eslint-disable no-param-reassign */
import { addUserContact, getUserContact } from "@/firebase";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { IContact } from "@/interfaces";
import type { RootState } from ".";

export const getContact = createAsyncThunk(
  "contact/getContact",
  async (userId: string) => getUserContact(userId),
);

export const saveContact = createAsyncThunk(
  "contact/saveContact",
  async ({
    contact,
    userId,
  }: {
    userId: string;
    contact: Omit<IContact, "id" | "userId">;
  }) => addUserContact(userId, contact),
);

const emptyContact = (): IContact => ({
  addressLine1: "",
  addressLine2: "",
  city: "",
  countryCode: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  state: "",
  zipCode: "",
  id: "",
  userId: "",
});

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact: emptyContact(),
  },
  reducers: {
    setContact: (state, action) => {
      state.contact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.contact = action.payload === null ? emptyContact() : action.payload;
    });

    builder.addCase(saveContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });
  },
});

export const { setContact } = contactSlice.actions;

export const selectContact = (state: RootState) => state.contact.contact;

export default contactSlice.reducer;
