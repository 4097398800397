/* eslint-disable import/prefer-default-export */
import type { User } from "firebase/auth";
import _ from "lodash";
import type { IAppUser } from "@/interfaces";

export const pickUserFields = (user: User) =>
  _.pick(user, [
    "uid",
    "email",
    "displayName",
    "emailVerified",
    "photoURL",
    "isAnonymous",
  ]) satisfies IAppUser;
