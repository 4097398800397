import type { DocumentSnapshot, QuerySnapshot } from "firebase/firestore";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  runTransaction,
  updateDoc,
  arrayUnion,
  arrayRemove,
  query,
  where,
  getDocs,
  setDoc,
  initializeFirestore,
  orderBy,
  limit,
} from "firebase/firestore";
import _ from "lodash";
import formatISO from "date-fns/formatISO";
import type {
  IConversation,
  IListConversation,
  IContact,
  IDomain,
  IDeploy,
  IWebsiteData,
} from "@/interfaces";
import { ConversationType } from "@/interfaces";
import Purchase from "@/entities/Purchase";
import cookies, { COOKIE_NAME } from "@/cookies";
import app from "./app";
import { auth } from "./auth";

export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export const generatedSiteCollection = collection(
  db,
  "generated-sites",
).withConverter({
  fromFirestore: (snapshot: DocumentSnapshot): IWebsiteData => {
    const data = (snapshot.data() ?? {}) as IWebsiteData;

    return {
      ...data,
      lastDeployDate: data.lastDeployDate
        ? formatISO(snapshot.data()?.lastDeployDate.toDate())
        : null,
      id: snapshot.id,
    };
  },
  toFirestore: ({ ...modelObject }) => modelObject,
});
export const deployCollection = collection(db, "deploys").withConverter({
  fromFirestore: (snapshot: DocumentSnapshot): IDeploy => {
    const data = snapshot.data() ?? {};

    return {
      id: snapshot.id,
      userId: data.userId,
      createdAt: formatISO(data.createdAt.toDate()),
      lastStatusChange: formatISO(data.lastStatusChange.toDate()),
      status: data.status,
      websiteId: data.websiteId,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toFirestore: ({ id, ...modelObject }) => modelObject,
});
export const conversationCollection = collection(db, "chat-gpt-conversations");
export const templateCollection = collection(db, "templates");
export const domainCollection = collection(db, "domains").withConverter({
  fromFirestore: (snapshot: DocumentSnapshot): IDomain => {
    const data = snapshot.data() ?? {};

    return {
      type: data.type ?? "purchased",
      nameServers: data.nameServers ?? [],
      reserved: !!data.reserved,
      websiteId: data.websiteId,
      name: data.name,
      userId: data.userId,
      id: snapshot.id,
      paid: !!data.paid,
      purchaseId: data.purchaseId,
      txtRecord: data.txtRecord,
      txtRecordVerified: data.txtRecordVerified,
      aws: {
        operationId: data.aws?.operationId ?? null,
        hostedZoneId: data.aws?.hostedZoneId ?? null,
        certificateArn: data.aws?.certificateArn ?? null,
        certificateIssued: !!data.aws?.certificateIssued,
        distributionId: data.aws?.distributionId ?? null,
        distributionDomain: data.aws?.distributionDomain ?? null,
        certificateRecord: data.aws?.certificateRecord ?? null,
        certificateRecordVerified: data.aws?.certificateRecordVerified ?? null,
        distributionDomainVerified:
          data.aws?.distributionDomainVerified ?? null,
      },
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toFirestore: ({ id, ...modelObject }) => modelObject,
});
export const purchaseCollection = collection(db, "purchases").withConverter({
  fromFirestore: (snapshot: DocumentSnapshot): Purchase => {
    const data = snapshot.data() ?? {};

    return new Purchase({
      id: snapshot.id,
      userId: data.userId,
      amount: data.amount,
      approved: data.approved ?? false,
      currency: data.currency,
      extraData: data.extraData ?? {},
      isTest: data.isTest ?? false,
      provider: data.provider,
      providerPaymentId: data.providerPaymentId,
      status: data.status,
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toFirestore: ({ id, ...modelObject }) => modelObject,
});
export const userSettingCollection = collection(db, "user-settings");
export const settingCollection = collection(db, "settings");
export const contactCollection = collection(db, "contact-data").withConverter({
  fromFirestore: (snapshot: DocumentSnapshot): IContact => {
    const data = snapshot.data() ?? {};

    return {
      id: snapshot.id,
      userId: data.userId,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      state: data.state,
      zipCode: data.zipCode,
      countryCode: data.countryCode,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toFirestore: ({ id, ...modelObject }) => modelObject,
});

export const getSetting = async (settingKey: string) => {
  const d = await getDoc(doc(db, "settings", settingKey));

  return d.data()?.value ?? null;
};

export const getUserContact = async (userId: string) => {
  const docRef = doc(contactCollection, userId);
  const d = await getDoc(docRef);
  if (!d.exists()) {
    return null;
  }
  const data = d.data();

  return data;
};

export const addUserContact = async (
  userId: string,
  contact: Omit<IContact, "id" | "userId">,
) => {
  const docRef = doc(contactCollection, userId);
  await setDoc(docRef, { ...contact, userId, id: userId });

  return { ...contact, id: docRef.id, userId } as IContact;
};

export const getUserDomains = async (userId: string) => {
  const querySnapshot = await getDocs(
    query(domainCollection, where("userId", "==", userId)),
  );

  return querySnapshot.docs.map((d) => d.data());
};

export const getUserSettings = async (userId: string) => {
  const docRef = doc(userSettingCollection, userId);
  const d = await getDoc(docRef);
  if (!d.exists()) {
    return { userId };
  }
  const data = d.data();

  return data;
};

export const saveUserSettings = async (
  userId: string,
  settings: Record<string, unknown>,
) => {
  const docRef = doc(userSettingCollection, userId);
  await setDoc(docRef, { ...settings, userId }, { merge: true });
};

export const getOnePurchase = async (purchaseId: string) => {
  const docRef = doc(purchaseCollection, purchaseId);
  const d = await getDoc(docRef);
  const data = d.data();

  return data ?? null;
};

export const getDomainByPurchaseId = async (purchaseId: string) => {
  const q = query(
    domainCollection,
    where("purchaseId", "==", purchaseId),
    where("userId", "==", auth.currentUser?.uid ?? ""),
  );
  const { docs } = await getDocs(q);
  const [d] = docs;
  return d?.data();
};

export const onUserDomainsUpdate = (
  userId: string,
  cb: (snapshot: QuerySnapshot<IDomain>) => void,
) => {
  const q = query(domainCollection, where("userId", "==", userId));

  const unsub = onSnapshot(q, cb, (err) => {
    // eslint-disable-next-line no-console
    console.error("[onUserDomainsUpdate]", err);
  });

  return unsub;
};

export const onWebsiteDeploysUpdate = (
  userId: string,
  websiteId: string,
  cb: (snapshot: QuerySnapshot<IDeploy>) => void,
) => {
  const q = query(
    deployCollection,
    where("userId", "==", userId),
    where("websiteId", "==", websiteId),
    orderBy("createdAt", "desc"),
    limit(1),
  );

  const unsub = onSnapshot(q, cb, (err) => {
    // eslint-disable-next-line no-console
    console.error(
      "[onWebsiteDeploysUpdate]",
      `[userId:${userId}]`,
      `[websiteId:${websiteId}]`,
      err,
    );
  });

  return unsub;
};

export const getUserConversations = async (userId: string) => {
  const q = query(
    conversationCollection,
    where("userId", "==", userId),
    where("type", "==", ConversationType.Base),
    orderBy("createdAt", "desc"),
  );
  const { docs } = await getDocs(q);

  return docs.map((d) => {
    const data = d.data();

    return {
      name: data.name,
      type: data.type,
      id: data.conversationId,
      docId: d.id,
      favourite: !!data.favourite,
    } satisfies IListConversation;
  });
};

export const getOneConversation = async (
  userId: string,
  conversationId: string,
  type: ConversationType,
) => {
  const q = query(
    conversationCollection,
    where("conversationId", "==", conversationId),
    where("userId", "==", userId),
    where("type", "==", type),
  );
  const { docs, size } = await getDocs(q);
  if (size === 0) {
    return {
      id: conversationId,
      docId: "",
      name: "",
      type,
      userId,
      conversation: [],
      websiteId: conversationId,
      favourite: false,
    } satisfies IConversation;
  }
  const [d] = docs;
  const data = d.data();

  return {
    id: data.conversationId,
    docId: d.id,
    name: data.name,
    type: data.type,
    userId: data.userId,
    conversation: data.conversation,
    websiteId: data.websiteId,
    favourite: !!data.favourite,
  } satisfies IConversation;
};

export const updateConversation = async (
  docId: string,
  data: { favourite: boolean },
) => {
  const ref = doc(conversationCollection, docId);
  await updateDoc(ref, { ...data });
};

export const getTemplate = async (id: string) => {
  const ref = doc(templateCollection, id);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  // eslint-disable-next-line no-console
  console.log("No such document!");
  return null;
};

export const getGeneratedSite = async (id: string) => {
  const ref = doc(generatedSiteCollection, id);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  // eslint-disable-next-line no-console
  console.log("No such document!");
  return null;
};

export const updateGeneratedSite = async (
  id: string,
  updateData: Record<string, any>,
) => {
  const ref = doc(generatedSiteCollection, id);
  await runTransaction(db, async (t) => {
    const document = await t.get(ref);
    const data = document.data()!;

    Object.keys(updateData).forEach((key) => {
      const [baseKey] = key.split(".");
      _.set(data, key, updateData[key]);
      t.update(ref, { [baseKey]: data[baseKey as keyof IWebsiteData] });
    });
  });
};

export const updateTemplate = async (
  id: string,
  updateData: Record<string, any>,
) => {
  const ref = doc(templateCollection, id);

  // @TODO
  if (auth.currentUser?.uid === "bgcutU5Ht5VLWNmlLXwQZL3G4y13") {
    return;
  }

  try {
    await updateDoc(ref, updateData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const addGeneratedSiteSectionItem = async (
  id: string,
  section: string,
  data: Record<string, any>,
) => {
  const ref = doc(generatedSiteCollection, id);
  const updateData = { [section]: arrayUnion(data) };
  // eslint-disable-next-line no-console
  console.log(id, section, updateData);
  await updateDoc(ref, updateData);
};

export const deleteGeneratedSiteSectionItem = async (
  id: string,
  section: string,
  index: number | string,
) => {
  const ref = doc(generatedSiteCollection, id);
  await runTransaction(db, async (t) => {
    const document = await t.get(ref);
    const data = document.data() ?? {};
    const el = _.get(data, `${section}.${index}`);

    t.update(ref, { [section]: arrayRemove(el) });
  });
};

export const onGeneratedSiteUpdate = (
  id: string,
  cb: (snapshot: DocumentSnapshot) => void,
) => {
  const ref = doc(generatedSiteCollection, id);

  const unsub = onSnapshot(ref, cb, (err) => {
    // eslint-disable-next-line no-console
    console.error("[onGeneratedSiteUpdate]", "[ID]", id, err);
    if (err.message.includes("Missing or insufficient permissions.")) {
      cookies.remove(COOKIE_NAME);
    }
  });

  return unsub;
};

export const onDomainUpdate = (
  id: string,
  cb: (snapshot: DocumentSnapshot) => void,
) => {
  const ref = doc(domainCollection, id);

  const unsub = onSnapshot(ref, cb, (err) => {
    // eslint-disable-next-line no-console
    console.error("[onDomainUpdate]", "[ID]", id, err);
  });

  return unsub;
};

export const onPurchaseUpdate = (
  providerPaymentId: string,
  cb: (snapshot: QuerySnapshot<Purchase>) => void,
) => {
  const q = query(
    purchaseCollection,
    where("userId", "==", auth.currentUser?.uid ?? ""),
    where("providerPaymentId", "==", providerPaymentId),
  );
  const unsub = onSnapshot(
    q,
    (s) => {
      cb(s as QuerySnapshot<Purchase>);
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.error("[onPurchaseUpdate]", err);
    },
  );

  return unsub;
};
