import { signOut } from "@/firebase";
import { useAppSelector } from "@/store";
import { selectUser } from "@/store/auth";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import type React from "react";
import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const UserMenu = memo(() => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = useCallback(async () => {
    await signOut();

    setTimeout(() => {
      navigate("/login");
    }, 1000);
  }, [navigate]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open menu">
        <Button
          color="inherit"
          onClick={handleOpenUserMenu}
          endIcon={
            <Avatar
              alt={user.displayName ?? user.email ?? ""}
              src={user?.photoURL ?? ""}
            />
          }
        >
          {user.email ?? ""}
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
});

export default UserMenu;
