/* eslint-disable no-param-reassign */
import type { PaletteMode } from "@mui/material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserSettings as getUserSettingsFunc,
  saveUserSettings as saveUserSettingsFunc,
} from "@/firebase";
import type { RootState } from ".";

interface SettingsState {
  mode: PaletteMode;
}

export const getUserSettings = createAsyncThunk(
  "settings/getUserSettings",
  async ({ userId }: { userId: string }) => getUserSettingsFunc(userId),
);

export const saveUserSettings = createAsyncThunk(
  "settings/saveUserSettings",
  async (settings: Partial<SettingsState>, { getState }) => {
    const state = getState() as RootState;
    return saveUserSettingsFunc(state.auth.user.uid, settings);
  },
);

const initialState: SettingsState = {
  mode: "light",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserSettings.fulfilled, (state, { payload }) => {
      Object.assign(state, payload);
    });

    builder
      .addCase(saveUserSettings.pending, (state, { meta }) => {
        Object.assign(state, meta.arg);
      })
      .addCase(saveUserSettings.fulfilled, (state, { meta }) => {
        Object.assign(state, meta.arg);
      });
  },
});

export const selectMode = (state: RootState) => state.settings.mode;

export default settingsSlice.reducer;
