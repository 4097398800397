import type { Middleware } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import { showMessage } from "../actions/flashMessage";

const errorMiddleware: Middleware = (store) => (next) => (action) => {
  const type = action.type.split("/");
  if (type.length < 3) {
    return next(action);
  }

  if (type[2] === "rejected") {
    if (!["AbortError"].includes(action.error.name as string)) {
      captureException(action.error);

      if (action.payload) {
        const { message } = action.payload;
        if (Array.isArray(message)) {
          store.dispatch<any>(showMessage(message[0], "error"));
        } else {
          store.dispatch<any>(showMessage(message, "error"));
        }
      } else {
        store.dispatch<any>(showMessage(action.error.message, "error"));
      }
    }
  }

  return next(action);
};

export default errorMiddleware;
