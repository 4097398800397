/* eslint-disable no-param-reassign */
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  getGeneratedSite as getGeneratedSiteFunc,
  getTemplate as getTemplateFunc,
  updateGeneratedSite as updateGeneratedSiteFunc,
  getUserConversations as getUserConversationsFunc,
  getOneConversation as getOneConversationFunc,
  updateConversation,
  getValue,
} from "@/firebase";
import _ from "lodash";
import type { ConversationType, IListConversation } from "@/interfaces";
import api from "@/api";
import type { RootState } from ".";

export interface BaseConversationItem {
  id: string;
  role: string;
  content: string | null;
}

export interface ConversationItem {
  id: string;
  role: string;
  content: string;
}

export type Section =
  | "Services"
  | "Portfolio"
  | "About"
  | "Team"
  | "Testimonials";

interface ConvesationState {
  generatedId: string | null;
  id: string | null;
  list: BaseConversationItem[];
  history: IListConversation[];
  websiteId: string;
  template: string;
  filesToRender: string[];
  sections: Section[];
  showIframe: boolean;
  typing: boolean;
  sending: boolean;
}

const initialState: ConvesationState = {
  id: null,
  generatedId: null,
  list: [],
  history: [],
  websiteId: "",
  template: "",
  filesToRender: [],
  sections: [],
  showIframe: false,
  typing: false,
  sending: false,
};

export const ask = createAsyncThunk(
  "conversation/ask",
  async (
    {
      msgObj,
      type,
    }: {
      msgObj: {
        role: string;
        content: any;
        id: any;
      };
      type: ConversationType;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const {
      conversation: { id: conversationId, websiteId },
    } = state;

    await api.ask(msgObj.content, conversationId!, type, websiteId);
  },
  {
    condition({ msgObj }, { getState }) {
      const state = getState() as RootState;
      const {
        conversation: { id: conversationId, websiteId },
      } = state;

      if (
        conversationId === null ||
        websiteId === null ||
        websiteId.length === 0 ||
        msgObj.content.length === 0
      ) {
        return false;
      }

      return true;
    },
  },
);

export const getConversations = createAsyncThunk(
  "conversation/getConversations",
  async ({ userId: id }: { userId: string }) => getUserConversationsFunc(id),
);

export const getOneConversation = createAsyncThunk(
  "conversation/getOneConversation",
  async ({
    userId,
    id,
    type,
  }: {
    userId: string;
    id: string;
    type: ConversationType;
  }) => getOneConversationFunc(userId, id, type),
);

export const getGeneratedSite = createAsyncThunk(
  "conversation/getGeneratedSite",
  async (id: string) => getGeneratedSiteFunc(id),
);

export const getTemplate = createAsyncThunk(
  "conversation/getTemplate",
  async (id: string) => getTemplateFunc(id),
);

export const updateGeneratedSiteTemplate = createAsyncThunk(
  "conversation/updateGeneratedSiteTemplate",
  async ({ id, template }: { id: string; template: string }) => {
    await updateGeneratedSiteFunc(id, { template });

    return { template };
  },
);

export const markConversationFavourite = createAsyncThunk(
  "conversation/markConversationFavourite",
  async ({ id, favourite }: { id: string; favourite: boolean }) => {
    await updateConversation(id, { favourite });
  },
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setTyping: (state, { payload }: PayloadAction<boolean>) => {
      state.typing = payload;
    },
    setSending: (state, { payload }: PayloadAction<boolean>) => {
      state.sending = payload;
    },
    setConversationId: (state, { payload }: PayloadAction<string | null>) => {
      state.id = payload;
    },
    setGeneratedConversationId: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.generatedId = payload;
    },
    setConversationList: (
      state,
      { payload }: PayloadAction<BaseConversationItem[]>,
    ) => {
      state.list = payload;
    },
    addConversationItem: (
      state,
      { payload }: PayloadAction<BaseConversationItem>,
    ) => {
      state.list.push(payload);
    },
    removeConversationItem: (
      state,
      { payload }: PayloadAction<BaseConversationItem>,
    ) => {
      const index = state.list.map((i) => i.id).indexOf(payload.id);
      state.list.splice(index, 1);
    },
    setWebsiteId: (state, { payload }: PayloadAction<string>) => {
      state.websiteId = payload;
    },
    setTemplate: (state, { payload }: PayloadAction<string>) => {
      state.template = payload;
    },
    setSections: (state, { payload }) => {
      state.sections = payload;
    },
    setShowIframe: (state, { payload }) => {
      state.showIframe = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversations.pending, (state) => {
        state.history = [];
      })
      .addCase(getConversations.fulfilled, (state, { payload }) => {
        state.history = payload;
      });

    builder
      .addCase(getOneConversation.pending, (state, { meta }) => {
        if (meta.arg.id !== state.id) {
          state.list = [];
        }
      })
      .addCase(getOneConversation.fulfilled, (state, { payload }) => {
        state.list = payload.conversation;
        state.id = payload.id;
        state.websiteId = payload.websiteId ?? "";
        state.showIframe = false;
        state.template = "";
        state.typing = false;
        state.sending = false;
      });

    builder
      .addCase(getGeneratedSite.fulfilled, (state, { payload }) => {
        if (payload !== null) {
          state.template = payload.template ?? "";
        } else {
          state.template = "";
        }
      })
      .addCase(getGeneratedSite.rejected, (state) => {
        state.template = "";
      });

    builder
      .addCase(getTemplate.fulfilled, (state, { payload }) => {
        if (payload !== null) {
          state.sections = payload.sections;
          state.filesToRender = payload.filesToRender;
        } else {
          state.sections = [];
          state.filesToRender = [];
        }
      })
      .addCase(getTemplate.rejected, (state) => {
        state.sections = [];
        state.filesToRender = [];
      });

    builder.addCase(
      updateGeneratedSiteTemplate.fulfilled,
      (state, { payload }) => {
        state.template = payload.template;
      },
    );

    builder.addCase(
      markConversationFavourite.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id, favourite },
          },
        },
      ) => {
        const conv = _.find(state.history, { docId: id });
        if (conv) {
          conv.favourite = favourite;
        }
      },
    );

    builder
      .addCase(ask.pending, (state, { meta }) => {
        state.sending = true;
        state.typing = true;
        state.list.push(meta.arg.msgObj);
      })
      .addCase(ask.rejected, (state, { meta }) => {
        const index = state.list.map((i) => i.id).indexOf(meta.arg.msgObj.id);
        state.list.splice(index, 1);

        state.sending = false;
      })
      .addCase(ask.fulfilled, (state) => {
        state.sending = false;
      });
  },
});

export const {
  setConversationList,
  addConversationItem,
  setSections,
  setTemplate,
  setWebsiteId,
  removeConversationItem,
  setConversationId,
  setGeneratedConversationId,
  setTyping,
  setSending,
} = conversationSlice.actions;

export const selectTyping = (state: RootState) => state.conversation.typing;
export const selectSending = (state: RootState) => state.conversation.sending;
export const selectConversationId = (state: RootState) => state.conversation.id;
export const selectGeneratedConversationId = (state: RootState) =>
  state.conversation.generatedId;
export const selectConversation = createSelector(
  (state: RootState) => state.conversation.list,
  (list) => {
    const postPromptsRaw = getValue("postPrompts");
    const postPrompts: string[] = postPromptsRaw?.length
      ? JSON.parse(postPromptsRaw)
      : [];
    // eslint-disable-next-line no-console
    console.log("postPrompts", postPrompts);

    return list
      .filter((i): i is ConversationItem => i.content !== null)
      .map((i) => {
        let { content } = i;

        postPrompts.forEach((postPrompt) => {
          content = content.replace(postPrompt, "");
        });

        return {
          ...i,
          content: content.trim(),
        };
      });
  },
);
export const selectConversationHistory = (state: RootState) =>
  state.conversation.history;
export const selectWebsiteId = (state: RootState) =>
  state.conversation.websiteId;
export const selectTemplate = (state: RootState) => state.conversation.template;
export const selectSections = (state: RootState) => state.conversation.sections;
export const selectFilesToRender = (state: RootState) =>
  state.conversation.filesToRender;

export default conversationSlice.reducer;
