import type { ReactPayPalScriptOptions } from "@paypal/react-paypal-js";

export default {
  apiBaseUrl: import.meta.env.REACT_API_BASE_URL,
  // apiBaseUrl: import.meta.env.DEV
  //   ? "http://localhost:3002"
  //   : "https://site-generator-api.threestars.uk",
  pusher: { appKey: import.meta.env.REACT_APP_PUSHER_KEY },
  paypal: {
    clientId: import.meta.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    components: "buttons",
    intent: "capture",
    // locale: "en_US",
  } satisfies ReactPayPalScriptOptions,
  settings: {
    chat: {
      showUpload: false,
    },
    drawer: {
      width: 250,
    },
  },
} as const;
