/* eslint-disable import/prefer-default-export */
import {
  getStorage,
  getMetadata as getMetadataFunc,
  ref as storageRef,
} from "firebase/storage";
import app from "./app";

export const storage = getStorage(app);

export const getMetadata = (url: string) =>
  getMetadataFunc(storageRef(storage, url));
