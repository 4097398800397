import { useEffect, useMemo } from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { selectToken, selectTokenExpired, selectUser } from "@/store/auth";
import { getTemplates } from "@/store/template";
import { useAppDispatch, useAppSelector } from "@/store";
import _ from "lodash";
import * as Sentry from "@sentry/react";
import { resetLoading as resetDomainsLoading } from "@/store/domain";
import router from "@/router";
import useFlashMessages from "@/hooks/useFlashMessages";
import ErrorFallbackComponent from "@/components/ErrorFallbackComponent";
import { useAuthStateChanged, useIdTokenChanged } from "@/hooks/firebase";

const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const tokenExp = useAppSelector(selectTokenExpired);
  const token = useAppSelector(selectToken);

  useFlashMessages();
  useAuthStateChanged();
  useIdTokenChanged();

  useEffect(() => {
    dispatch(resetDomainsLoading());
  }, [dispatch]);

  const getTemplatesDebounced = useMemo(
    () => _.debounce(() => dispatch(getTemplates()), 500),
    [dispatch],
  );

  useEffect(() => {
    if (user.uid.length > 0 && !tokenExp && token.length > 0) {
      getTemplatesDebounced();
    }
    return () => {};
  }, [user.uid, getTemplatesDebounced, tokenExp, token]);

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallbackComponent} showDialog>
      <RouterProvider router={router} />
      <Toaster />
    </Sentry.ErrorBoundary>
  );
};

export default App;
