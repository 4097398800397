import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/firebase";
import { useAppDispatch } from "@/store";
import { pickUserFields } from "@/utils";
import { emptyUser, setLoading, setUser } from "@/store/auth";
import { getUserSettings } from "@/store/settings";

export const useAuthStateChanged = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const unsubscribeAuthState = onAuthStateChanged(auth, (u) => {
      dispatch(setLoading(false));

      if (!u) {
        dispatch(setUser(emptyUser()));
        return;
      }
      const userFields = pickUserFields(u);
      dispatch(setUser(userFields));
      if (u.emailVerified) {
        dispatch(getUserSettings({ userId: u.uid }));
      }
    });

    return () => {
      unsubscribeAuthState();
    };
  }, [dispatch]);
};

export default useAuthStateChanged;
