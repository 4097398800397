import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import image from "./image";
import conversation from "./conversation";
import template from "./template";
import website from "./website";
import domain from "./domain";
import contact from "./contact";
import country from "./country";
import drawer from "./drawer";
import settings from "./settings";
import flashMessage from "./flashMessage";

export default combineReducers({
  auth,
  image,
  conversation,
  template,
  website,
  domain,
  contact,
  country,
  drawer,
  settings,
  flashMessage,
});
