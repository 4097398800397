/* eslint-disable react/jsx-props-no-spreading */
import AppBar from "@/components/AppBar";
import Drawer from "@/components/Drawer";
import config from "@/config";
import ColorModeContext from "@/context/ColorModeContext";
import { useAppDispatch, useAppSelector } from "@/store";
import { selectDrawerOpen, setDrawerOpen } from "@/store/drawer";
import type { PaletteMode } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect } from "react";
import { Outlet, useMatches } from "react-router-dom";
import ErrorBoundaryLayout from "./ErrorBoundaryLayout";

const appBarHeight = "64px";

const MainLayout = ({
  mode = "light",
  hideDrawer = true,
}: {
  mode?: PaletteMode;
  hideDrawer?: boolean;
}) => {
  const { changeColorMode } = useContext(ColorModeContext);
  const [match] = useMatches();
  const dispatch = useAppDispatch();

  const drawerOpen = useAppSelector(selectDrawerOpen);

  useEffect(() => {
    changeColorMode(mode);
  }, [mode, changeColorMode]);

  useEffect(() => {
    dispatch(setDrawerOpen(false));
  }, [dispatch, match.id]);

  return (
    <ErrorBoundaryLayout>
      <Box sx={{ display: "flex", height: "100%" }}>
        <AppBar hideDrawerToggle={hideDrawer} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            mt: 8,
            mb: 0,
            height: `calc(100% - ${appBarHeight})`,
            overflowY: "auto",
            ...(drawerOpen && {
              width: `calc(100% - ${config.settings.drawer.width}px)`,
              ml: `${config.settings.drawer.width}px`,
            }),
          }}
        >
          {!hideDrawer && <Drawer />}
          <Outlet />
        </Box>
      </Box>
    </ErrorBoundaryLayout>
  );
};

export default MainLayout;
